@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

@layer base {
  html,
  #root {
    @apply h-full;
  }

  body {
    @apply h-full bg-gray-100;
  }

  h1,
  h2,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    @apply text-gray-700;
  }

  .main {
    @apply mb-16 ml-6 mr-6 mt-6;
  }

  /* typography */
  .title {
    @apply text-3xl font-bold text-black mb-4;
  }

  .subtitle {
    @apply text-xl font-bold text-gray-900;
  }

  .subtext {
    @apply text-gray-500 text-sm font-semibold;
  }

  label {
    @apply text-gray-800 font-bold text-xs my-2 border-0;
  }

  /* input */
  input {
    @apply border-none rounded-lg w-full focus:outline-none;
  }

  .input {
    @apply bg-gray-100 text-black p-2;
  }

  /* card */
  .card {
    @apply px-4 py-2 my-4 bg-white rounded-lg shadow-sm;
  }

  .card-title {
    @apply text-lg font-bold text-gray-800;
  }

  /* tabs */
  .bottom-tab {
    @apply flex flex-row items-center p-2 text-gray-800 text-sm font-bold rounded-lg;
  }

  .active-bottom-tab {
    @apply p-2 text-primary-dark text-sm font-bold rounded-lg bg-primary-light;
  }

  /* buttons */
  button {
    @apply flex items-center rounded-lg px-4 py-2 text-center text-sm font-bold bg-white text-gray-700 focus:outline-none;
  }

  .btn-primary-light {
    @apply bg-primary-light text-gray-500;
  }

  .btn-primary-dark {
    @apply bg-primary-dark text-white;
  }

  .btn-secondary-light {
    @apply bg-secondary-light text-gray-500;
  }

  .btn-secondary-dark {
    @apply bg-secondary-dark text-white;
  }

  /* error */
  .error {
    @apply text-red-500 text-xs font-bold italic mt-1;
  }

  .icon {
    @apply h-4 w-4;
  }

  /* etc */
  .skeleton {
    @apply h-2 bg-gray-400 rounded;
  }
}
